import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Popover } from 'antd';
import { MoreOutlined, CaretDownOutlined, FolderOutlined } from '@ant-design/icons';
import { roles } from '../../types';
import { RoleGuard } from '../roles';

import styles from './menu.module.scss';

type Props = {
    title?: string;
    icon?: any;
    children?: any;
    options?: any;
    onClick?: () => void;
    id?: string;
    isCollapsed?: boolean;
    defaultOpen?: boolean;
    allowedRoles?: roles;
    isActiveScrollEnabled?: boolean;
    hasActiveHighlight?: boolean;
};

export function Menu({
    children,
    title = 'title',
    icon: Icon = FolderOutlined,
    options = null,
    onClick = undefined,
    id = undefined,
    isCollapsed = false,
    defaultOpen = false,
    allowedRoles = ['admin', 'trainer', 'analyzer'],
    isActiveScrollEnabled = false,
    hasActiveHighlight = false,
}: Props) {
    const params = useLocation();

    const path = params.pathname;
    const isActive = path.includes(String(id));

    const [isOpen, setIsOpen] = useState(isActive || defaultOpen);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const hasChildren = Array.isArray(children) ? children.length : !!children;
    const hasOptions = Array.isArray(options) && options.length;

    const headerRef = useRef(null);

    const hide = () => {
        setIsPopoverOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        setIsPopoverOpen(newOpen);
    };

    useEffect(() => {
        if (headerRef.current) {
            const elem: HTMLElement = headerRef.current;

            if (isActiveScrollEnabled && isActive) {
                elem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    }, [isCollapsed, isActiveScrollEnabled, isActive]);

    return (
        <RoleGuard allowedRoles={allowedRoles}>
            <div className={styles.container}>
                <div
                    ref={headerRef}
                    className={`${styles.header} ${isActive && hasActiveHighlight ? `${styles['header-active']}` : ''}`}
                >
                    <div
                        className={styles.left}
                        onClick={typeof onClick === 'function' ? onClick : () => hasChildren && setIsOpen(!isOpen)}
                    >
                        <div className={styles['icon-container']}>
                            <Icon className={styles.icon} />
                        </div>
                        <Typography className={styles.title}>{title}</Typography>
                    </div>
                    <div className={styles.right}>
                        {!!hasChildren && (
                            <div
                                className={`${styles['caret-container']}`}
                                onClick={
                                    typeof onClick === 'function' ? onClick : () => hasChildren && setIsOpen(!isOpen)
                                }
                            >
                                <CaretDownOutlined
                                    className={`${styles.caret} ${isOpen ? styles['caret-open'] : ''}`}
                                />
                            </div>
                        )}

                        {!!hasOptions && (
                            <Popover
                                trigger={'click'}
                                placement={'right'}
                                content={<div onClick={hide}>{options}</div>}
                                overlayInnerStyle={{
                                    backgroundColor: '#001f42',
                                    padding: '4px',
                                    marginLeft: '28px',
                                    overflow: 'hidden',
                                }}
                                arrow={false}
                                open={isPopoverOpen}
                                onOpenChange={handleOpenChange}
                            >
                                <div className={`${styles['more-container']}`}>
                                    <MoreOutlined className={styles.more} />
                                </div>
                            </Popover>
                        )}
                    </div>
                </div>
                <div className={`${styles.content} ${!isOpen ? `${styles['content-closed']}` : ''}`}>
                    {!!hasChildren && children}
                </div>
            </div>
        </RoleGuard>
    );
}
