import React from 'react';
import { Skeleton, Typography } from 'antd';
import { Menu } from 'components/menu';
import { useNavigate } from 'react-router-dom';
import { DOCUMENT_CREATE } from 'data/consts/routes';
import { formTypes } from '../../../pages/form-crud/data/data';
import { FileOutlined } from '@ant-design/icons';
import { IFormData } from 'data/models/formModel';
import { formService } from 'pages/form-crud/data/formService';
import { useContextValues } from 'components/providers/MainProvider';
import { useMutation } from '@tanstack/react-query';

import { useRoles } from '../../../hooks';
import { isValuesInArray } from '../../../helpers';

import styles from './document-tree.module.scss';

type Props = {
    isCollapsed?: boolean;
    isLoading?: boolean;
    data: IFormData[] | undefined;
};

const getOptions = ({ navigate, userRoles }) => {
    return [
        isValuesInArray(['admin'], userRoles) ? (
            <Typography key={'1'} className={styles['option']} onClick={() => navigate(DOCUMENT_CREATE)}>
                Create document
            </Typography>
        ) : null,
    ].filter((option) => !!option);
};

const getDocumentOptions = ({ navigate, document, mutators, userRoles }) => {
    return [
        isValuesInArray(['admin'], userRoles) ? (
            <Typography
                key={'1'}
                className={styles['option']}
                onClick={() => navigate(`/document/${document._id}/edit`)}
            >
                Edit document
            </Typography>
        ) : null,
        <Typography key={'2'} className={styles['option']} onClick={() => navigate(`/label/document/${document._id}`)}>
            Label document
        </Typography>,
        <Typography key={'3'} className={styles['option']} onClick={() => mutators.rebuildModel(document._id)}>
            Train document
        </Typography>,
        <Typography
            key={'4'}
            className={styles['option']}
            onClick={() => navigate(`/${document._id}/rebuild-logs`, { state: { heading: 'Training logs' } })}
        >
            Training logs
        </Typography>,
        isValuesInArray(['admin'], userRoles) ? (
            <Typography
                key={'5'}
                className={styles['option']}
                onClick={() => navigate(`/document/${document._id}/model-settings`)}
            >
                Model settings
            </Typography>
        ) : null,
    ].filter((option) => !!option);
};

export function DocumentTree({ isCollapsed = false, isLoading = true, data = [] }: Props) {
    const navigate = useNavigate();
    const { notificationApi } = useContextValues();
    const { userRoles } = useRoles();

    const { mutate: mutateRebuildModel } = useMutation({
        mutationFn: (formId: string) => {
            return formService.rebuildModel(formId);
        },
        onSuccess: () => {
            notificationApi.success({
                message: 'Model rebuilding',
                description:
                    "The selected document is training, if you'd like to view the training logs, you can do so by navigating to 'Training logs'.",
                placement: 'topRight',
            });
        },
        onError: (error: any) => {
            notificationApi.error({
                message: 'Failed to train document',
                description: error?.response?.data?.message,
                placement: 'topRight',
            });
        },
    });

    if (isLoading) {
        return <Skeleton.Input active className={`f-width bg-primary br-primary ${styles.skeleton}`} />;
    }

    const documents = data || [];

    const mutators = {
        rebuildModel: mutateRebuildModel,
    };

    return (
        <div className={`${styles.container} ${isCollapsed ? `${styles['container-collapsed']}` : ''}`}>
            <Menu
                id={'/document'}
                title={'Documents'}
                options={getOptions({ navigate, userRoles })}
                isCollapsed={isCollapsed}
                defaultOpen={true}
            >
                {documents
                    ?.filter((item) => item?.type === formTypes.document)
                    .map?.((document) => (
                        <Menu
                            key={document._id}
                            id={document._id}
                            title={document.title}
                            onClick={() => navigate(`/label/document/${document._id}`)}
                            options={getDocumentOptions({ navigate, document, mutators, userRoles })}
                            icon={FileOutlined}
                            isCollapsed={isCollapsed}
                            isActiveScrollEnabled={true}
                            hasActiveHighlight={true}
                        />
                    ))}
            </Menu>
        </div>
    );
}
