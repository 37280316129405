import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Input, Result, Skeleton, Typography, Select, Modal } from 'antd';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { ReloadOutlined, CloseCircleFilled } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { formService } from '../data/formService';
import { IFormValues } from '../data/model';
import { useContextValues } from '../../../components/providers/MainProvider';
import { DOCUMENT_TREE_VIEW, FORM_EDIT, FORM_TREE_VIEW } from '../../../data/consts/apiQuery';
import { AsyncRender } from '../../../components/common/async-render';
import { formTypes } from '../data/data';

import styles from '../form-crud.module.scss';

interface IProps {
    type: 'Document' | 'Paged Form';
}

export function FormEdit({ type }: IProps) {
    const queryClient = useQueryClient();
    const { notificationApi } = useContextValues();
    const [form] = Form.useForm();
    const { formId } = useParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [buildMode, setBuildMode] = useState<'template' | 'neural' | null>(null);
    const queryData = useQuery({
        queryKey: [FORM_EDIT, formId],
        queryFn: () => formService.getForm(formId),
        retry: 0,
        onSuccess: (res) => {
            form.setFieldsValue({
                title: res.title,
                description: res.description,
                postScript: res.postScript,
                buildMode: res.buildMode,
            });
            setBuildMode(res.buildMode);
        },
    });

    const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
        mutationFn: (values: IFormValues) => {
            return formService.updateForm(values, formId as string);
        },
        onSuccess: () => {
            notificationApi.success({
                message: type === formTypes.document ? 'Edited document' : 'Edited paged form',
                description:
                    type === formTypes.document ? 'The document has been edited.' : 'The paged form has been edited.',
                placement: 'topRight',
            });

            queryClient.invalidateQueries({
                queryKey: [type === formTypes.document ? DOCUMENT_TREE_VIEW : FORM_TREE_VIEW],
            });
            const newBuildMode = form.getFieldValue('buildMode');
            setBuildMode(newBuildMode);
            if (type === 'Paged Form' && buildMode !== newBuildMode) {
                setModalOpen(true);
            }
        },
        onError: (error: any) => {
            notificationApi.error({
                message: type === formTypes.document ? 'Failed to edit document' : 'Failed to edit paged form',
                description: error?.response?.data?.message,
                placement: 'topRight',
            });
        },
    });

    const handleFormSubmit = (values) => {
        mutateUpdate(values);
    };

    return (
        <div>
            <Modal
                width={700}
                closable={false}
                title="Retrain Pages?"
                open={modalOpen}
                className={styles.upload_modal}
                footer={
                    <div>
                        <Button
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            No
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                setModalOpen(false);
                                formService
                                    .trainAllPages(formId as string)
                                    .then(() => {
                                        notificationApi.success({
                                            message: 'Retrain all pages submitted successfully',
                                            description: 'You can see the status of the retraining in each pages logs',
                                            placement: 'topRight',
                                        });
                                    })
                                    .catch((err) => {
                                        notificationApi.error({
                                            message: 'Error retraining all pages',
                                            description: err?.response?.data?.message,
                                            placement: 'topRight',
                                        });
                                    });
                            }}
                        >
                            Yes
                        </Button>
                    </div>
                }
            >
                <div className="mt-4">
                    When switching between <b>Neural</b> and <b>Template</b> mode, all the sub-pages need to be
                    retrained or else you will get an error when you try to rebuild the Paged Form.
                    <br />
                    Would you like to retrain all pages now?
                </div>
            </Modal>

            <AsyncRender
                queryData={queryData}
                RenderSuccess={({ data }) => (
                    <>
                        <Typography.Paragraph className={styles.card__title}>
                            {type === formTypes.document ? 'Edit document' : 'Edit paged form'}
                        </Typography.Paragraph>

                        <Form form={form} layout="vertical" size="large" onFinish={handleFormSubmit}>
                            <Form.Item
                                label="Title"
                                name="title"
                                required
                                rules={[{ required: true, message: 'Title cannot be empty' }]}
                            >
                                <Input style={{ width: '50%' }} placeholder="Please enter a title" size="middle" />
                            </Form.Item>

                            <Form.Item label="Name" name="name">
                                <Input
                                    style={{ width: '50%' }}
                                    placeholder="Form name"
                                    size="middle"
                                    defaultValue={data?.name}
                                    disabled={true}
                                />
                            </Form.Item>

                            <Form.Item label="Description" name="description">
                                <Input.TextArea rows={4} placeholder="Please enter a description" size="middle" />
                            </Form.Item>

                            <Form.Item label="Post script" name="postScript">
                                <CodeEditor
                                    className={styles.form__script_field}
                                    language="js"
                                    placeholder="Please enter JS code."
                                    padding={10}
                                    minHeight={150}
                                />
                            </Form.Item>

                            <Form.Item label="Build Type" name="buildMode">
                                <Select
                                    style={{ width: 120 }}
                                    options={[
                                        { value: 'template', label: 'Template' },
                                        { value: 'neural', label: 'Neural' },
                                    ]}
                                />
                            </Form.Item>

                            <Button size={'middle'} loading={isLoadingUpdate} type="primary" htmlType="submit">
                                {isLoadingUpdate ? 'Updating' : 'Update'}
                            </Button>
                        </Form>
                    </>
                )}
                renderLoading={
                    <>
                        <Skeleton active />
                        <Skeleton active className="mt-8" />
                        <Skeleton active className="mt-8" />
                    </>
                }
                renderError={
                    <Result
                        title="Failed to load form"
                        subTitle="Please click on `Reload` to try again."
                        status="error"
                        icon={<CloseCircleFilled style={{ fontSize: 32 }} />}
                        extra={[
                            <Button
                                size={'middle'}
                                danger
                                type="primary"
                                key="reload"
                                icon={<ReloadOutlined rev="true" />}
                                onClick={() => {
                                    queryClient.invalidateQueries({ queryKey: [FORM_EDIT, formId] });
                                }}
                            >
                                Reload
                            </Button>,
                        ]}
                    />
                }
            />
        </div>
    );
}
